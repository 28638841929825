import React from "react"
import Contactpage from '../components/Company/Contact/Contact'

const Contact = () => {
  return (
    <>
      <Contactpage />
    </>
  )
}

export default Contact
