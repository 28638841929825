import React, { Component } from "react"
import Head from "../../head"
import Layout from "../../layout/layout.higherComponent"
import Title from "./title/title"
import Form from "./form/form"
import "./Contact.scss"

class ContactPage extends Component {
  render() {
    return (
      <>
        <Layout displayHeader="true">
          <Head title="Contact" />
          <div className="static">
            <Title />
            <Form />
          </div>
        </Layout>
      </>
    )
  }
}

export default ContactPage
